import { isPast, parse } from 'date-fns'
import * as yup from 'yup'
import stateCodes from 'lib/stateCodes'
import { validatePhoneNumber } from './utils'

export const dateRegex = /^(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}$/g

export const ssnRegex = /^\d{3}-\d{2}-\d{4}$/

export const passwordSchema = yup
  .string()
  .required('Required')
  .min(8, 'Must be at least 8 characters long')
  .matches(/^\S*$/, 'Cannot contain spaces')
  .matches(/[a-zA-Z]/, 'Must contain at least one letter')
  .matches(/\d/, 'Must contain at least one number')
  .matches(/[!?@#$%^&*]/, 'Must contain at least one special character')

export const passwordrules =
  'required: lower; required: upper; required: digit; required: [!#$%&*?@^]; minlength: 8;'

export const phoneSchema = yup
  .string()
  .required('Required')
  .test('phone', 'Must be a valid 10-digit phone number', (value) =>
    value ? validatePhoneNumber(value) : false
  )

export const dateOfBirthSchema = yup
  .string()
  .required('Required')
  .matches(dateRegex, 'Must be a valid date')
  .test('past-date', 'Cannot be a future date', (value) =>
    isPast(parse(value, 'P', new Date()))
  )

export const emailSchema = yup
  .string()
  .required('Required')
  .email('Must be a valid email')
  .max(60, ({ max }) => `Cannot be longer than ${max} characters`)

export const stateSchema = yup
  .string()
  .uppercase()
  .required('Required')
  .oneOf(Object.keys(stateCodes), 'Must be a valid 2-digit state code')

export const zipSchema = yup
  .string()
  .required('Required')
  .matches(/^([0-9]{5})(-[0-9]{4})?$/i, 'Must be a valid U.S. zip code')

export const ssnSchema = yup
  .string()
  .required('Required')
  .matches(ssnRegex, 'Must be a valid 9-digit SSN')

export const memberIdSchema = yup
  .string()
  .required('Required')
  .max(36, ({ max }) => `Cannot be longer than ${max} characters`)

export const nameSchema = yup
  .string()
  .required('Required')
  .max(25, ({ max }) => `Cannot be longer than ${max} characters`)
