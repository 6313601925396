import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useMemo } from 'react'
import { colors } from 'lib/root'
import { InsuranceCheckerState } from './InsuranceChecker'

export const InsuranceCheckerTable = ({
  state,
  isDialog,
}: {
  state: InsuranceCheckerState
  isDialog: boolean
}) => {
  const rows = [
    {
      service: 'First Dental Exam',
      outCost: 0,
      inCost: 0,
      cashCost: 375,
    },
    {
      service: 'Filling',
      outCost: [90, 225],
      inCost: [53, 133],
      cashCost: [113, 420],
    },
    {
      service: 'Non-Surgical Periodontal Treatment',
      outCost: [85, 213],
      inCost: [54, 136],
      cashCost: [175, 298],
    },
    {
      service: 'Emergency Exam',
      outCost: 0,
      inCost: 0,
      cashCost: 215,
    },
  ]

  const width = useMemo(() => {
    if (state === 'badResult') {
      return isDialog ? 380 : 500
    }
    return 1
  }, [state, isDialog])

  return (
    <Box
      sx={{
        textAlign: 'center',
        mx: 'auto',
        width,
      }}
    >
      <Box
        sx={{
          bgcolor: colors.core.green[600],
          px: 2,
        }}
      >
        <Table
          aria-label="service-cost-table"
          sx={[
            isDialog && { '& th, & td': { fontSize: '0.875rem !important' } },
          ]}
        >
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  pt: 2,
                  pb: 1.25,
                  px: 0,
                  lineHeight: 1,
                },
              }}
            >
              <TableCell>Service</TableCell>
              {state === 'outResult' ? (
                <>
                  <TableCell align="center" sx={{ width: '38%' }}>
                    Estimated Out-of-network cost
                  </TableCell>
                  <TableCell align="center" sx={{ width: '38%' }}>
                    Estimated In-network cost
                  </TableCell>
                </>
              ) : (
                <TableCell align="right" sx={{ width: '50%' }}>
                  Estimated cost
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.service}
                sx={{
                  '& td, & th': { py: 1.25, px: 0 },
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.service}
                </TableCell>
                {state === 'outResult' ? (
                  <>
                    <TableCell align="center">
                      {typeof row.outCost === 'number'
                        ? `$${row.outCost}`
                        : `$${row.outCost[0]}-$${row.outCost[1]}`}
                    </TableCell>
                    <TableCell align="center">
                      {typeof row.inCost === 'number'
                        ? `$${row.inCost}`
                        : `$${row.inCost[0]}-$${row.inCost[1]}`}
                    </TableCell>
                  </>
                ) : (
                  <TableCell align="right">
                    {typeof row.cashCost === 'number'
                      ? `$${row.cashCost}`
                      : `$${row.cashCost[0]}-$${row.cashCost[1]}`}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Typography
        variant="body1"
        sx={[
          {
            fontSize: '1rem !important',
            color: 'common.white',
            mt: 1.25,
            mx: 'auto',
          },
          isDialog && { fontSize: '0.6875rem !important', maxWidth: 385 },
        ]}
      >
        *We include a range because final cost can vary based on your location
        and the complexity of the treatment your dentist recommends.
      </Typography>
    </Box>
  )
}

export default InsuranceCheckerTable
