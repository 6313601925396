import { motion, useAnimation } from 'framer-motion'
import { FunctionComponent, ReactNode, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

interface ScrollAnimateProps {
  children: ReactNode
  className?: string
}

const ScrollAnimate: FunctionComponent<ScrollAnimateProps> = ({
  children,
  className,
}) => {
  const controls = useAnimation()

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <motion.div
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 40 },
      }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      ref={ref}
      className={className}
    >
      {children}
    </motion.div>
  )
}

export default ScrollAnimate
