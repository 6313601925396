import { SvgIcon, SvgIconProps } from '@mui/material'
import { FunctionComponent } from 'react'
import { toSxArray } from 'lib/utils'

const SearchIcon: FunctionComponent<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon {...props} sx={[{ fill: 'none' }, ...toSxArray(sx)]}>
    <circle
      cx="9.39967"
      cy="9.39998"
      r="8.31667"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M21.6666 21.6666L15.2666 15.2666"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </SvgIcon>
)

export default SearchIcon
