import {
  Autocomplete,
  Box,
  InputAdornment,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import { UseAutocompleteProps } from '@mui/material/useAutocomplete'
import { FunctionComponent, forwardRef } from 'react'
import { InsuranceProvider } from 'lib/api/insuranceProviders'
import { orderBy, toSxArray } from 'lib/utils'
import SearchIcon from './icons/SearchIcon'

type ProviderUseAutoCompleteProps = UseAutocompleteProps<
  InsuranceProvider,
  false,
  false,
  false
>

interface Props extends ProviderUseAutoCompleteProps {
  name: string
  label: string
  loading?: boolean
  sx?: SxProps<Theme>
}

const ProviderAutocomplete: FunctionComponent<Props> = forwardRef(
  ({ options, label, loading, name, sx, ...other }, ref) => {
    const disabled = !options || options.length === 0

    const sortedOptions = orderBy(
      options,
      [(option) => option.is_popular || false, 'name'],
      ['desc', 'asc'],
    )

    return (
      <Autocomplete
        {...other}
        options={sortedOptions}
        ListboxProps={{ sx: { maxHeight: '250px !important' } }}
        disabled={disabled}
        loading={loading}
        disableClearable
        forcePopupIcon={false}
        getOptionLabel={(option) => option.display_name || option.name}
        isOptionEqualToValue={(option, value) => {
          if (option) {
            return option.slug === value.slug
          }
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ display: 'flex', justifyContent: 'space-between !important' }}
            {...props}
          >
            <span>{option.display_name || option.name}</span>
            {option.is_popular && (
              <Box
                component="span"
                sx={{
                  pl: 1,
                  textTransform: 'uppercase',
                  fontSize: '0.875rem',
                  letterSpacing: '0.12em',
                }}
              >
                Popular
              </Box>
            )}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              'aria-label': label,
            }}
            fullWidth
          />
        )}
        ref={ref}
        sx={[
          {
            '& .MuiAutocomplete-inputRoot': {
              pr: 0,
              '&.Mui-disabled:before': {
                borderBottomStyle: 'solid',
              },
            },
          },
          ...toSxArray(sx),
        ]}
      />
    )
  },
)

export default ProviderAutocomplete
