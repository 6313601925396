import store from 'store2'
import { Insurance } from './api/insurance'
import { RecallPatient } from './api/patients'

export type UnauthenticatedPatient = {
  id: string
  email: string
  firstName: string
  lastName: string
  preferredName: string
  phone: string
  insurance: string
  medicaid: string
  activeInsurance: Insurance
}

export type BookingType = 'first' | 'return'

const sessionStore = store.session.namespace('booking')

const UNAUTHENTICATED_PATIENT_KEY = 'unauthenticatedPatient'
const RECALLED_PATIENT_KEY = 'recalledPatient'
const INSURANCE_CHECKER_EMAIL_KEY = 'insuranceCheckerEmail'
const BOOKING_TYPE_KEY = 'bookingType'
const INSURANCE_ID_KEY = 'insuranceId'
const EMERGENCY_TYPE_KEY = 'emergencyType'
const EMERGENCY_QUERY_IDS_KEY = 'emergencyQueryIds'
const EMERGENCY_IS_TEND_TREATMENT_KEY = 'emergencyIsTendTreatment'
const EMERGENCY_FREEFORM_QUESTION_KEY = 'emergencyFreeFormQuestion'

const getStoredItem = <T>(key: string): T | null => {
  return sessionStore.get(key)
}

const setStoredItem = <T>(key: string, item: T): void => {
  sessionStore.set(key, item)
}

const updateUnauthenticatedPatient = (
  updatedFields: Partial<UnauthenticatedPatient>,
): void => {
  const unauthenticatedPatient = getStoredItem<UnauthenticatedPatient>(
    UNAUTHENTICATED_PATIENT_KEY,
  ) || {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    preferredName: '',
    phone: '',
    insurance: '',
    medicaid: '',
    activeInsurance: null,
  }

  setStoredItem<UnauthenticatedPatient>(UNAUTHENTICATED_PATIENT_KEY, {
    ...unauthenticatedPatient,
    ...updatedFields,
  })
}

const bookingStore = {
  getUnauthenticatedPatient: (): UnauthenticatedPatient | null =>
    getStoredItem<UnauthenticatedPatient>(UNAUTHENTICATED_PATIENT_KEY),
  setUnauthenticatedPatient: (patient: UnauthenticatedPatient): void =>
    setStoredItem<UnauthenticatedPatient>(UNAUTHENTICATED_PATIENT_KEY, patient),
  removeUnauthenticatedPatient: (): void =>
    sessionStore.remove(UNAUTHENTICATED_PATIENT_KEY),
  updateUnauthenticatedPatientEmail: (email: string): void =>
    updateUnauthenticatedPatient({ email }),
  setRecalledPatient: (patient: RecallPatient): void =>
    setStoredItem<RecallPatient>(RECALLED_PATIENT_KEY, patient),
  getRecalledPatient: (): RecallPatient | null =>
    getStoredItem<RecallPatient>(RECALLED_PATIENT_KEY),
  setInsuranceCheckerEmail: (email: string): void =>
    setStoredItem<string>(INSURANCE_CHECKER_EMAIL_KEY, email),
  getInsuranceCheckerEmail: (): string | null =>
    getStoredItem<string>(INSURANCE_CHECKER_EMAIL_KEY),
  setBookingType: (bookingType: BookingType): void =>
    setStoredItem<string>(BOOKING_TYPE_KEY, bookingType),
  getBookingType: (): BookingType | null =>
    getStoredItem<BookingType>(BOOKING_TYPE_KEY),
  setInsuranceId: (insuranceId: string): void =>
    setStoredItem<string>(INSURANCE_ID_KEY, insuranceId),
  getInsuranceId: (): string | null => getStoredItem<string>(INSURANCE_ID_KEY),
  setEmergencyType: (emergencyType: string): void =>
    setStoredItem<string>(EMERGENCY_TYPE_KEY, emergencyType),
  getEmergencyType: (): string | null =>
    getStoredItem<string>(EMERGENCY_TYPE_KEY),
  setEmergencyQueryIds: (queryIds: string[]): void =>
    setStoredItem<string[]>(EMERGENCY_QUERY_IDS_KEY, queryIds),
  getEmergencyQueryIds: (): string[] | null =>
    getStoredItem<string[]>(EMERGENCY_QUERY_IDS_KEY),
  setEmergencyIsTendTreatment: (isTendTreatment: string): void =>
    setStoredItem<string>(EMERGENCY_IS_TEND_TREATMENT_KEY, isTendTreatment),
  getEmergencyIsTendTreatment: (): string | null =>
    getStoredItem<string>(EMERGENCY_IS_TEND_TREATMENT_KEY),
  setEmergencyFreeFormQuestion: (freeFormQuestion: string): void =>
    setStoredItem<string>(EMERGENCY_FREEFORM_QUESTION_KEY, freeFormQuestion),
  getEmergencyFreeFormQuestion: (): string | null =>
    getStoredItem<string>(EMERGENCY_FREEFORM_QUESTION_KEY),
}

export default bookingStore
