import { Box, SxProps, Theme, ThemeProvider } from '@mui/material'
import type { FunctionComponent, ReactElement, ReactNode } from 'react'
import { useContext } from 'react'
import { darkTheme } from 'lib/theme'
import { TypeBannerFields } from 'lib/types/ctf-marketing/TypeBanner'
import Banner from 'components/Banner'
import SkipNavLink from 'components/SkipNavLink'
import { AppContext } from 'components/contexts/AppContext'
import Footer from '../Footer'
import Header from '../Header'

const CONTENT_ID = 'content'

interface BuilderOrContentfulBannerData extends TypeBannerFields {
  showBanner?: boolean
}

interface Props {
  children: ReactNode
  slimHeader?: boolean
  slimFooter?: boolean
  footerPhoneNumber?: string
  banner?: BuilderOrContentfulBannerData
  useDarkTheme?: boolean
  profileHeader?: boolean
  headerSx?: SxProps<Theme>
  showFooter?: boolean
  isBuilderPage?: boolean
  logoColor?: string
  headerBookButtonHref?: string
  headerBackHref?: string
  hiddenFooter?: boolean
}

const Layout: FunctionComponent<Props> = ({
  children,
  slimHeader = false,
  slimFooter = false,
  footerPhoneNumber,
  banner,
  useDarkTheme = false,
  profileHeader = false,
  headerSx,
  showFooter = true,
  isBuilderPage = false,
  logoColor,
  headerBookButtonHref,
  headerBackHref,
  hiddenFooter = true,
}) => {
  const { mobileMenuIsShown, headerHeight, bannerHeight, isWebView } =
    useContext(AppContext)
  const showHeader = !isWebView

  const showBanner =
    !mobileMenuIsShown && (isBuilderPage ? banner.showBanner : banner)

  return (
    <>
      <Box component="header" display="contents">
        <SkipNavLink contentId={CONTENT_ID} />
        {showBanner && (
          <Banner
            href={banner.link}
            uppercase={banner.uppercase}
            showChevron={banner.showChevron}
            color={banner.color}
          >
            <span>
              <strong>{banner.text1}</strong>
            </span>
            <span> {banner.text2}</span>
          </Banner>
        )}

        {showHeader && isWebView !== null && (
          <Header
            component="div"
            slim={slimHeader}
            useDarkTheme={useDarkTheme}
            profileHeader={profileHeader}
            sx={headerSx}
            logoColor={logoColor}
            bookButtonHref={headerBookButtonHref}
            backHref={headerBackHref}
            showBackButton={!!headerBackHref}
          />
        )}
      </Box>

      <Box
        component="main"
        id={CONTENT_ID}
        sx={{
          ...(hiddenFooter && {
            minHeight: `calc(100vh - ${headerHeight + bannerHeight}px)`,
          }),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
      {showFooter && (
        <ThemeProvider theme={darkTheme}>
          <Footer slim={slimFooter} phoneNumber={footerPhoneNumber} />
        </ThemeProvider>
      )}
    </>
  )
}

export const getLayout = (page: ReactElement) => {
  const {
    slimHeader = false,
    slimFooter = false,
    banner,
    footerPhoneNumber,
    useDarkTheme = false,
    profileHeader = false,
    headerSx,
    logoColor,
    headerBookButtonHref,
    headerBackHref,
  } = page.props

  return (
    <Layout
      slimHeader={slimHeader}
      slimFooter={slimFooter}
      banner={banner}
      footerPhoneNumber={footerPhoneNumber}
      useDarkTheme={useDarkTheme}
      profileHeader={profileHeader}
      headerSx={headerSx}
      logoColor={logoColor}
      headerBookButtonHref={headerBookButtonHref}
      headerBackHref={headerBackHref}
    >
      {page}
    </Layout>
  )
}

export default Layout
