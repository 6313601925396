import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { sendGTMEvent } from '@next/third-parties/google'
import * as Sentry from '@sentry/nextjs'
import { parseCookies } from 'nookies'
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import useSWR from 'swr'
import * as Yup from 'yup'
import {
  CheckInsuranceStatus,
  InsuranceProvider,
  checkInsurance,
  fetchInsuranceProvidersByMarket,
} from 'lib/api/insuranceProviders'
import { createLead } from 'lib/api/leads'
import bookingStore from 'lib/bookingStore'
import { INSURANCE_DISCLAIMER } from 'lib/constants'
import { marketingClient } from 'lib/contentful'
import { nantes } from 'lib/fonts'
import { emailSchema } from 'lib/formSchema'
import { colors } from 'lib/root'
import { darkTheme } from 'lib/theme'
import { TypeMarketFields } from 'lib/types/ctf-marketing'
import { sleep, toSxArray } from 'lib/utils'
import CTAButton from 'components/CTAButton'
import ProviderAutocomplete from 'components/ProviderAutocomplete'
import Input from 'components/forms/Input'
import CheckIcon from 'components/icons/CheckIcon'
import DownArrow from 'components/icons/DownArrow'
import LeftLongArrow from 'components/icons/LeftLongArrow'
import InsuranceCheckerTable from './InsuranceCheckerTable'

const schema = Yup.object().shape({
  email: emailSchema,
  provider: Yup.object().required('Required'),
  market: Yup.string().required('Required'),
})

interface Props {
  heading: string
  body: string
  markets?: TypeMarketFields[]
  isDialog?: boolean
  sx?: SxProps<Theme>
  onStateChange?: (state: InsuranceCheckerState) => void
  isFullScreen?: boolean
}

export type InsuranceCheckerState =
  | 'initial'
  | 'loading'
  | 'inResult'
  | 'outResult'
  | 'badResult'

interface FormValues {
  email: string
  provider: InsuranceProvider
  market: string
}

const iconStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  m: '0 auto',
  color: colors.defaults.white,
  bgcolor: colors.core.green[500],
  borderRadius: '50%',
  fontSize: '3rem',
  '& svg': {
    display: 'flex',
  },
  width: 55,
  height: 55,
  mb: { xs: 3, sm: 4 },
}

const InsuranceChecker: FunctionComponent<Props> = ({
  heading,
  body,
  markets,
  isDialog = false,
  sx,
  onStateChange,
  isFullScreen = false,
}) => {
  const [state, setState] = useState<InsuranceCheckerState>('initial')
  const [isAentaWashingtonDC, setIsAetnaWashingtonDC] = useState(false)
  const [providerName, setProviderName] = useState('')
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const domRef = useRef(null)

  const isLightTheme = theme.palette.mode === 'light'

  const { data: fetchedMarkets } = useSWR(
    !markets || markets.length === 0 ? '/contentful/markets' : null,
    async () =>
      await marketingClient.getEntries<TypeMarketFields>({
        content_type: 'market',
      }),
  )

  if (fetchedMarkets) {
    markets = fetchedMarkets.items.map((i) => i.fields)
  }

  const {
    register,
    formState: { isSubmitting, isValid },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      provider: null,
      market: '',
    },
  })

  const currentMarket = watch('market')

  const insuranceProviderByMarketFetcher = async () => {
    return await fetchInsuranceProvidersByMarket(currentMarket)
  }

  const { data: providers, isLoading } = useSWR(
    currentMarket ? `/api/insurance-providers/${currentMarket}` : null,
    insuranceProviderByMarketFetcher,
  )

  useEffect(() => {
    register('provider')
  }, [register])

  useEffect(() => {
    if (!currentMarket) {
      return
    }

    setValue('provider', null)
  }, [currentMarket, setValue])

  useEffect(() => {
    if (onStateChange) {
      onStateChange(state)
    }
  }, [state, onStateChange])

  const statesWithTable = ['outResult', 'badResult']

  const maxWidth = useMemo(() => {
    if (isDialog) {
      if (statesWithTable.includes(state)) {
        return 500
      }
      return 432
    }

    return state === 'initial' ? 614 : 827
  }, [state, isDialog, statesWithTable])

  const onSubmit: SubmitHandler<FormValues> = async ({
    email,
    provider,
    market,
  }) => {
    if (domRef?.current) {
      domRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    setState('loading')
    await sleep(1800)

    let status: CheckInsuranceStatus = null
    try {
      const response = await checkInsurance(provider.slug, market)
      status = response.status

      setProviderName(provider.name)

      const { patientId } = parseCookies()

      sendGTMEvent({
        event: 'lead-captured',
        campaign: isDialog
          ? 'Insurance HP pop-up test'
          : 'marketing-site-insurance-checker',
        market,
        patientType: !!patientId ? 'EXIST' : 'NEW',
      })

      sendGTMEvent({
        event: isDialog ? 'Insurance HP pop-up test' : 'insurance-page-checker',
        patient_type: !!patientId ? 'return' : 'new',
        insurance_status: status,
        insurer: provider.slug,
        market,
      })

      switch (status) {
        case 'IN-NETWORK':
          setState('inResult')
          setIsAetnaWashingtonDC(
            market === 'washington-dc' &&
              provider.slug === 'AETNA_DENTAL_PLANS',
          )
          break
        case 'OUT-OF-NETWORK':
          setState('outResult')
          break
        default:
          setState('badResult')
          break
      }
    } catch (e) {
      Sentry.captureException(e)
      setState('badResult')
    }

    if (email) {
      createLead({
        email,
        kind: isDialog ? 'POPUP_Insurance_HP' : 'INS-CHECKER',
        campaign: {
          utm_campaign: isDialog ? '04_2023_popup_HP_insurance' : 'ins-checker',
          utm_source: 'website',
          utm_medium: 'web',
          utm_content: market,
        },
        responses: {
          insurance_provider: provider.slug,
          insurance_status: status,
        },
        studio_market: market,
      })

      bookingStore.setInsuranceCheckerEmail(email)
    }
  }

  const handleReset = () => {
    setState('initial')
  }

  const headingStyles = [
    {
      color:
        isLightTheme && state === 'initial'
          ? colors.core.copper[600]
          : colors.core.paper,
      fontSize: { xs: '1.5rem', md: '2.5rem' },
      textAlign: 'center',
      margin: '0 auto',
    },
    isDialog && { fontSize: { xs: '1.5rem', md: '2rem' } },
  ]

  const bodyStyles = [
    {
      color:
        isLightTheme && state === 'initial'
          ? colors.core.grey[700]
          : colors.core.paper,
      fontFamily: nantes.style.fontFamily,
      pt: { xs: 1, md: 4 },
      pb: { xs: 5, md: 7 },
      textAlign: 'center',
      fontSize: { xs: '1.1rem', md: '1.5rem' },
    },
    isDialog && {
      pt: { xs: 1, md: 2.25 },
      pb: { xs: 2, md: 5 },
      fontSize: { xs: '1rem', md: '1.25rem' },
    },
    statesWithTable.includes(state) && {
      pb: { xs: 3, md: 3 },
    },
  ]

  const resultHeading = useMemo(() => {
    switch (state) {
      case 'inResult':
        return isAentaWashingtonDC ? (
          'We’re in network with your insurer (except at our Ballston Studio).'
        ) : (
          <>
            Good news!
            <br />
            We’re in-network with {providerName}.
          </>
        )
      case 'outResult':
        return 'You’re out of network—but we accept your insurance.'
      case 'badResult':
        return 'Sorry, we do not accept your insurance.'
      default:
        return ''
    }
  }, [state, providerName])

  const resultBody = useMemo(() => {
    switch (state) {
      case 'inResult':
        return '*We’re in-network with most plans offered by your insurer, but don’t accept HMO, DMO, or Medicare plans. If we don’t accept your plan, we’ll be in touch before your visit.'
      case 'outResult':
        return 'Many dental plans have out-of-network benefits. Here are estimated costs for our most common services.'
      case 'badResult':
        return 'The good news: you can still be treated at Tend! Here are estimated costs for our most common services.'
      default:
        return ''
    }
  }, [state])

  return (
    <Box
      ref={domRef}
      sx={[
        {
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          pt: { xs: 8, sm: 6, md: 15 },
          px: { xs: 3 },
          pb: { xs: 9, md: 18 },
          bgcolor:
            isLightTheme && state === 'initial'
              ? colors.core.paper
              : colors.core.green[700],
        },
        isDialog && {
          pt: { xs: 4, md: 9.5 },
          pb: { xs: 3, md: 5.5 },
        },
        isFullScreen && {
          height: '100%',
          alignItems: 'center',
        },
        ...toSxArray(sx),
      ]}
    >
      {state === 'loading' && (
        <CircularProgress
          size={80}
          color="secondary"
          sx={{
            position: 'absolute',
            margin: 'auto',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            color: colors.core.paper,
          }}
        />
      )}

      <Box sx={{ maxWidth }}>
        <Box
          hidden={state === 'initial' || state === 'loading'}
          sx={{
            position: 'absolute',
            p: 0,
            left: { xs: 16, sm: 8 },
            top: { xs: 16, sm: 8 },
          }}
        >
          <IconButton
            size="medium"
            aria-label="back"
            onClick={handleReset}
            sx={{
              color: 'common.white',
            }}
          >
            <LeftLongArrow size="md" />
          </IconButton>
        </Box>

        {state !== 'initial' && state !== 'loading' ? (
          <ThemeProvider theme={darkTheme}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                textAlign: { sm: 'center' },
              }}
            >
              {state === 'inResult' && (
                <Box sx={[iconStyles, { bgcolor: colors.core.green[500] }]}>
                  <CheckIcon />
                </Box>
              )}

              {resultHeading && (
                <Typography component="h2" variant="h2" sx={headingStyles}>
                  {resultHeading}
                </Typography>
              )}

              {resultBody && (
                <Typography sx={bodyStyles}>{resultBody}</Typography>
              )}

              {statesWithTable.includes(state) && (
                <InsuranceCheckerTable state={state} isDialog={isDialog} />
              )}

              <Box
                sx={[
                  {
                    display: 'flex',
                    justifyContent: 'center',
                    mt: { xs: 3, sm: 4 },
                  },
                  !isDialog && {
                    '& a': {
                      height: { xs: 48, sm: 64 },
                      minWidth: { sm: 583 },
                    },
                  },
                  isDialog && {
                    '& a': {
                      height: 48,
                      minWidth: { sm: 320 },
                    },
                  },
                ]}
              >
                <CTAButton
                  size="large"
                  long={!smDown && !isDialog}
                  fullWidth={smDown}
                />
              </Box>
            </Box>
          </ThemeProvider>
        ) : (
          <>
            <Box
              style={state === 'loading' ? { visibility: 'hidden' } : undefined}
            >
              <Typography component="h2" variant="h2" sx={headingStyles}>
                {heading}
              </Typography>
              <Typography color="textSecondary" sx={bodyStyles}>
                {body}
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={[
                  {
                    position: 'relative',
                    textAlign: 'center',
                    '& > div, & > button': {
                      mb: { xs: 5, sm: 7 },
                    },
                    '& > div:last-of-type': {
                      mb: 2,
                    },
                  },
                  isDialog && {
                    '& > div, & > button': {
                      mb: { xs: 3 },
                    },
                  },
                ]}
              >
                <Input
                  type="email"
                  name="email"
                  control={control}
                  fullWidth
                  autoComplete="email"
                  label="Your email"
                  InputLabelProps={{ id: 'insurance-checker-email-input' }}
                  inputProps={{
                    'aria-labelledby': 'insurance-checker-email-input',
                  }}
                />

                {markets?.length > 0 && (
                  <Controller
                    control={control}
                    name="market"
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel
                          id="market-selector-label"
                          htmlFor="market-selector"
                        >
                          Your location
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="market-selector-label"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'center',
                            },
                          }}
                          IconComponent={DownArrow}
                          inputProps={{ id: 'market-selector' }}
                          sx={[
                            {
                              textAlign: 'left',
                            },
                          ]}
                        >
                          {markets
                            .filter((market) => market.bookingEnabled)
                            .filter((market) =>
                              isDialog ? market.slug !== 'nashville' : true,
                            )
                            .map((market) => (
                              <MenuItem value={market.slug} key={market.slug}>
                                {market.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                )}

                {currentMarket && (
                  <Controller
                    control={control}
                    name="provider"
                    render={({ field }) => (
                      <ProviderAutocomplete
                        {...field}
                        label={
                          isLoading ? 'Loading insurers...' : 'Your insurer'
                        }
                        loading={isLoading}
                        options={providers}
                        onChange={(_, value) => {
                          field.onChange(value)
                        }}
                        data-testid="provider-autocomplete"
                      />
                    )}
                  />
                )}

                <Button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  variant={isLightTheme ? 'contained' : 'outlined'}
                  color="primary"
                  size="large"
                  fullWidth
                  sx={{
                    mt: { xs: 3, md: 4 },
                    maxWidth: isDialog ? undefined : 400,
                    height: { xs: 48, md: 64 },
                    fontSize: { xs: '1rem', md: '1.25rem' },
                  }}
                >
                  Check insurance
                </Button>
              </Box>
              <Box
                sx={[
                  {
                    mt: { md: 4 },
                    py: { xs: 1.75, md: 3 },
                    px: { xs: 1.75, md: 2 },
                    border: `3px solid ${
                      isLightTheme
                        ? colors.core.grey[300]
                        : colors.core.green[600]
                    }`,
                  },
                  isDialog && {
                    mt: { xs: 0, md: 2 },
                    p: { xs: 0, md: 2 },
                    borderWidth: { xs: 0, md: 3 },
                  },
                ]}
              >
                <Typography
                  sx={[
                    {
                      color: isLightTheme
                        ? colors.core.grey[700]
                        : colors.core.paper,
                      fontSize: { xs: '1rem', md: '1.125rem' },
                    },
                    isDialog && {
                      fontSize: '0.75rem !important',
                    },
                  ]}
                >
                  {INSURANCE_DISCLAIMER}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default InsuranceChecker
