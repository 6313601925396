import { callApi } from '../api'

interface Lead {
  first_name?: string
  last_name?: string
  email: string
  phone?: string
  kind: string
  lead_number?: number
  studio?: string
  campaign: {
    utm_source?: string
    utm_medium?: string
    utm_campaign?: string
    utm_content?: string
  }
  responses?: Record<string, unknown>
  number_of_leads_of_this_kind?: number
  studio_market?: string
}

export const createLead = (data: Lead) => {
  const url = '/api/leads'

  return callApi(url, {
    method: 'POST',
    data,
  }) as Promise<Lead>
}

export const fetchLeadsRemaining = (kind: string) => {
  const url = `/api/leads/${kind}/remaining`

  return callApi(url, {
    method: 'GET',
  }) as Promise<{ number_remaining: number }>
}
