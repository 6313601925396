import { SvgIcon } from '@mui/material'

const DownArrow = (props) => (
  <SvgIcon {...props}>
    <path
      d="M7 10L12 15L17 10"
      fill="none"
      stroke={props.htmlColor || 'currentColor'}
      strokeWidth={props.strokeWidth || '1.5'}
    />
  </SvgIcon>
)

export default DownArrow
