import { StandardTextFieldProps, TextField } from '@mui/material'
import { FunctionComponent } from 'react'
import { Control, Controller } from 'react-hook-form'

export type InputProps = StandardTextFieldProps & { control: Control<any> }

const Input: FunctionComponent<InputProps> = ({
  control,
  name,
  defaultValue,
  onChange,
  ...other
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    render={({ field: { ref, ...field }, fieldState }) => (
      <TextField
        {...field}
        {...other}
        onChange={(e) => {
          onChange && onChange(e)
          field.onChange(e)
        }}
        inputRef={ref}
        error={!!fieldState.error}
        helperText={fieldState.error?.message}
      />
    )}
  />
)

export default Input
