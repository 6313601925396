import queryString from 'query-string'
import { callApi } from '../api'

type InsuranceStatus =
  | 'IN-NETWORK'
  | 'OUT-OF-NETWORK'
  | 'DO-NOT-ACCEPT'
  | 'MEDICAID'

export interface InsuranceProvider {
  id: string
  status: InsuranceStatus
  name: string
  slug: string
  is_popular: boolean
  is_active: boolean
  requires_zip_codes: boolean
  display_name: string
}

export type CheckInsuranceStatus =
  | 'IN-NETWORK'
  | 'OUT-OF-NETWORK'
  | 'DO-NOT-LIST'
  | 'HMO'
  | 'MEDICAID'
  | 'KNOWN-COPAY'
  | 'DO-NOT-ACCEPT'

export const fetchInsuranceProviders = (
  params: {
    is_active: boolean | undefined
  } = { is_active: true },
) => {
  const url = queryString.stringifyUrl({
    url: '/api/insurance-providers',
    query: params,
  })
  return callApi(url, {
    method: 'GET',
  }) as Promise<InsuranceProvider[]>
}

export const fetchInsuranceProvidersByMarket = (marketSlug: string) => {
  const url = queryString.stringifyUrl({
    url: `/api/insurance-providers/${marketSlug}`,
    query: {
      is_active: true,
    },
  })

  return callApi(url, { method: 'GET' }) as Promise<InsuranceProvider[]>
}

export const checkInsurance = (providerSlug: string, marketSlug: string) => {
  const url = queryString.stringifyUrl({
    url: `/api/insurance-providers/${marketSlug}/${providerSlug}`,
  })
  return callApi(url, { method: 'GET' }) as Promise<{
    status: CheckInsuranceStatus
  }>
}
