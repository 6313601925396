import { SvgIcon, SvgIconProps } from '@mui/material'
import { toSxArray } from 'lib/utils'

const CheckIcon = ({ sx, ...props }: SvgIconProps) => (
  <SvgIcon
    viewBox="0 0 10 8"
    fill="currentColor"
    {...props}
    sx={[{ fill: 'none' }, ...toSxArray(sx)]}
  >
    <desc>Check Icon</desc>
    <path d="M1 3.69231L3.52632 6L9 1" stroke="#F9F9F6" strokeWidth="1.5" />
  </SvgIcon>
)

export default CheckIcon
